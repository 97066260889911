import React from "react";
import Vimeo from '@vimeo/player';
import ScrollMagic from "scrollmagic";

class Videos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideVideoPlaceholder: false,
            iframeHide: true
        };
        this.videoRef = React.createRef();
    }
    HandleVideoEvt = () => {
        const ScrollController = new ScrollMagic.Controller();
        const vimeoPlayer = new Vimeo(this.videoRef.current);
        // Set Video To Pause
        this.setState({ hideVideoPlaceholder: true });
        vimeoPlayer.play();
        // Attach Scroll Video
        const Scrolls = new ScrollMagic.Scene({ triggerElement: `#${this.props.id} .ratio`, triggerHook: 0.7, duration: '100%' }).addTo(ScrollController);
        Scrolls.on('enter', function () {
            vimeoPlayer.play();
        }).on('leave', function () {
            vimeoPlayer.pause();
        });
    }
    componentDidMount() {
        const vimeoPlayer = new Vimeo(this.videoRef.current);
        vimeoPlayer.pause();
    }

    render() {
        return (
            <div id={this.props.id}>
                <div className="ratio ratio-16x9">
                    {!this.state.hideVideoPlaceholder && (
                        <div className="video-placeholder" onClick={this.HandleVideoEvt.bind(this)} >
                            <img className="img-fluid w-100" src={require(`../assets/${this.props.image}`)} alt={this.props.title} />
                            <div className="play-btn"><svg className="img-fluid" fill="#505a76" width="800px" height="800px" viewBox="0 0 562.746 562.746"><path d="M281.37,0C125.977,0,0.003,125.974,0.003,281.373c0,155.399,125.974,281.373,281.373,281.373 c155.393,0,281.367-125.974,281.367-281.373C562.743,125.974,436.769,0,281.37,0z M484.212,305.425L192.287,471.986 c-23.28,13.287-42.154,2.326-42.154-24.479V115.239c0-26.805,18.874-37.766,42.154-24.479l291.925,166.562 C507.491,270.602,507.491,292.145,484.212,305.425z" /></svg></div>
                        </div>
                    )}
                    <iframe className={!this.state.hideVideoPlaceholder ? 'opacity-0' : ''} ref={this.videoRef} title={this.props.title} src={this.props.url} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" frameBorder="0" data-ready="true"></iframe>
                </div>
            </div>
        );
    }
}
export default (Videos);