import React from "react";

class SvgIcon extends React.Component {
    render() {
        return (
            <svg className="icon img-fluid w-100" fill={this.props.color} xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox={`0, 0, ${this.props.width}, ${this.props.height}`}>
                <path fillRule="evenodd" d={this.props.icon} />
            </svg>
        );
    }
}
export default (SvgIcon);